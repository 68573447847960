import Loader from './Loader';

const AppLoader = () => {
  return (
    <div className="w-screen h-[100dvh] fixed top-0 left-0 z-[9999] bg-black bg-opacity-60 flex justify-center items-center">
      <div className="bg-white p-6 rounded-md">
        <Loader />
      </div>
    </div>
  );
};

export default AppLoader;
