export class Role {
  type: string;
  constructor(type: string) {
    this.type = type;
  }
}

export class AppRole {
  roles: Array<Role>;
  constructor(roles: Array<Role>) {
    this.roles = roles;
  }

  hasRole = (role: Role): boolean => {
    for (let r = 0; r < this.roles.length; r++) {
      if (this.roles[r].type === role.type) {
        return true;
      }
    }
    return false;
  }
}

