/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom';
import { clearLocalStorage, useLocalStorage } from './useLocalStorage';
import { FC, createContext, useContext, useMemo, useState } from 'react';
import { JWT } from '../config';
import { getUserForToken } from '../token';
import { AppUser } from '../types/AppUser';

type LoginFunction = (token: string) => void;

interface AuthContextType {
  jwt: string | undefined;
  user: AppUser | undefined;
  login: LoginFunction;
  logout: Function;
}

interface AuthProviderProps {
  children: any;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [jwt, setJWT] = useLocalStorage(JWT, undefined);
  const [user, setUser] = useState<AppUser | undefined>(
    jwt ? getUserForToken(jwt) : undefined
  );
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = (token: string): void => {
    setJWT(token);
    setUser(getUserForToken(token));
    navigate('/');
  };

  // call this function to sign out logged in user
  const logout = () => {
    setJWT(undefined);
    setUser(undefined);
    clearLocalStorage();
    navigate('/signin', { replace: true });
  };

  const value = useMemo(
    () => ({
      jwt,
      login,
      logout,
      user,
    }),
    [jwt, user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
