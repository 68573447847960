/* eslint-disable react-hooks/exhaustive-deps */
import { i18n } from 'i18next';
import { createContext, useContext, useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';

type TranslateFuncType = (
  key: string,
  fallback?: string,
  props?: { [key: string]: string | number }
) => string;

interface GorseleTranslationContextType {
  i18n: i18n;
  tk: TranslateFuncType;
}

interface GorseleTranslationProviderProps {
  children: any;
}

const GorseleTranslationContext = createContext<GorseleTranslationContextType>(
  {} as GorseleTranslationContextType
);

const replaceProps = (
  s: string,
  p?: { [key: string]: string | number }
): string => {
  if (!p) return s;
  const pKeys = Object.keys(p);
  let ns = s;
  pKeys.forEach((pkey) => {
    ns = ns.replace(`%${pkey}%`, p[pkey].toString());
  });
  return ns;
};

export const GorseleTranslationProvider: FC<
  GorseleTranslationProviderProps
> = ({ children }) => {
  const { t, i18n } = useTranslation();

  const tk: TranslateFuncType = (
    key: string,
    fallback?: string,
    props?: { [key: string]: string | number }
  ) => {
    const done = t(key);
    if (!fallback || done !== 'keymissing') return replaceProps(done, props);
    return replaceProps(fallback, props);
  };

  const value = useMemo(
    () => ({
      i18n,
      tk,
    }),
    [i18n]
  );
  return (
    <GorseleTranslationContext.Provider value={value}>
      {children}
    </GorseleTranslationContext.Provider>
  );
};

export const useGorseleTranslation = () => {
  return useContext(GorseleTranslationContext);
};
