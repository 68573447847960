import { lazy } from "react";
import { AppConfigType, APPID } from "../types/AppConfig";
import { AppRoute } from "../types/AppRoute";
import { TaskAdd01Icon, TaskDone01Icon } from "@hugeicons/react";
import nl from './exxon/locales/nl.json';
import en from './exxon/locales/en.json';
import './exxon/style.css';

const ExxonOrders = lazy(() => import("../views/exxon/orders/ExxonOrders"));
const PlannedExxonOrders = lazy(() => import("../views/exxon/orders/planned/PlannedExxonOrders"));

const app: AppConfigType = {
    id: APPID.exxon,
    name: "Gorselé Exxon Transport",
    description: "Gorselé Exxon is a public planning app for partner Exxon",
    version: 2,
    signInEmail: true,
    locales: {
        nl: {
            translation: nl,
            label: 'Nederlands',
        },
        en: {
            translation: en,
            label: 'English'
        },
    },
    routes: [
        new AppRoute({
            path: '/',
            label: 'navigation.orders',
            element: ExxonOrders,
            nav: true,
            icon: TaskAdd01Icon,
            index: true,
        }),
        new AppRoute({
            path: '/plannedorders',
            label: 'navigation.myOrders',
            element: PlannedExxonOrders,
            nav: true,
            icon: TaskDone01Icon,
        }),
    ]
}

export default app;