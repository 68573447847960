import { AppConfigType, APPID } from "../types/AppConfig";
import { AppRoute } from "../types/AppRoute";
import { AppRoles } from "../AppRoles";
import { AnalyticsUpIcon, ArrowDataTransferVerticalIcon, Book02Icon, CargoShipIcon, ChartLineData02Icon, ComputerCloudIcon, ContactBookIcon, DatabaseSync01Icon, GarageIcon, LaborIcon, MapsLocation01Icon, SailboatCoastalIcon, Task01Icon, ThumbsUpDownIcon, UserGroupIcon, UserIcon, UserMultiple02Icon } from "@hugeicons/react";
import { lazy } from "react";

const DashBoard = lazy(() => import("../views/oneoffice/dashboard/Dashboard"));
const ISOQMS = lazy(() => import("../views/oneoffice/iso/ISOQMS"));
const PhoneBook = lazy(() => import("../views/oneoffice/phonebook/PhoneBook"));
const DriverMap = lazy(() => import("../views/oneoffice/map/DriverMap"));
const Orders = lazy(() => import("../views/oneoffice/orders/Orders"));
const GorseleOneOrders = lazy(() => import("../views/oneoffice/gorseleone/orders/GorseleOneOrders"));
const GorseleOneOrdersNoPrice = lazy(() => import("../views/oneoffice/gorseleone/ordersnoprice/GorseleOneOrdersNoPrice"));
const GorseleOneUsers = lazy(() => import("../views/oneoffice/gorseleone/users/GorseleOneUsers"));
const LoodswezenPlanning = lazy(() => import("../views/oneoffice/loodswezen/planning/LoodswezenPlanning"));
const LoodswezenStandby = lazy(() => import("../views/oneoffice/loodswezen/standby/LoodswezenStandby"));
const OrderChecks = lazy(() => import("../views/oneoffice/orderchecks/OrderChecks"));
const Customers = lazy(() => import("../views/oneoffice/customers/customers/Customers"));
const Customergroups = lazy(() => import("../views/oneoffice/customers/customergroups/Customergroups"));
const Assigners = lazy(() => import("../views/oneoffice/customers/assigners/Assigners"));
const Suppliers = lazy(() => import("../views/oneoffice/suppliers/Suppliers"));
const Employers = lazy(() => import("../views/oneoffice/staff/employers/Employers"));
const Partners = lazy(() => import("../views/oneoffice/staff/partners/Partners"));
const StaffMembers = lazy(() => import("../views/oneoffice/staff/staff/StaffMembers"));
const AdvancePayments = lazy(() => import("../views/oneoffice/staff/advancePayments/AdvancePayments"));
const Ships = lazy(() => import("../views/oneoffice/ships/Ships"));
const Vehicles = lazy(() => import("../views/oneoffice/garage/vehicles/Vehicles"));
const VehicleModels = lazy(() => import("../views/oneoffice/garage/vehiclemodels/VehicleModels"));
const VehicleCategories = lazy(() => import("../views/oneoffice/garage/vehiclecategories/VehicleCategories"));
const Materials = lazy(() => import("../views/oneoffice/garage/material/Materials"));
const TomTomTracks = lazy(() => import("../views/oneoffice/garage/tomtomtracks/TomTomTracks"));
const Manuals = lazy(() => import("../views/oneoffice/manuals/Manuals"));
const Users = lazy(() => import("../views/oneoffice/users/Users"));
const ReplicationStatus = lazy(() => import("../views/oneoffice/replication/status/ReplicationStatus"));
const ReplicationLogs = lazy(() => import("../views/oneoffice/replication/logs/ReplicationLogs"));
const ReplicationJobs = lazy(() => import("../views/oneoffice/replication/jobs/ReplicationJobs"));
const TaskManagerStatus = lazy(() => import("../views/oneoffice/taskmanager/status/TaskManagerStatus"));
const TaskManagerLogs = lazy(() => import("../views/oneoffice/taskmanager/logs/TaskManagerLogs"));
const TaskManagerJobs = lazy(() => import("../views/oneoffice/taskmanager/jobs/TaskManagerJobs"));

const app: AppConfigType = {
    id: APPID.oneoffice,
    name: "Gorselé OneOffice",
    description: "Gorselé OneOffice is a private app for dispatchers and management",
    version: 2,
    routes: [
        new AppRoute({
            path: '/',
            label: 'Dashboard',
            element: DashBoard,
            nav: true,
            icon: ChartLineData02Icon,
            index: true,
        }),
        new AppRoute({
            path: '/isoqms',
            label: 'ISOQMS',
            element: ISOQMS,
            nav: true,
            icon: AnalyticsUpIcon,
        }),
        new AppRoute({
            path: '/phonebook',
            label: 'Telefoonboek',
            element: PhoneBook,
            nav: true,
            icon: ContactBookIcon,
        }),
        new AppRoute({
            path: '/map',
            label: 'Kaart',
            element: DriverMap,
            nav: true,
            icon: MapsLocation01Icon,
        }),
        new AppRoute({
            path: '/orders',
            label: 'Orders',
            element: Orders,
            nav: true,
            icon: Task01Icon,
        }),
        new AppRoute({
            path: '/gorseleone',
            label: 'Gorselé One',
            nav: true,
            icon: ComputerCloudIcon,
        }),
        new AppRoute({
            path: '/gorseleone/waitingorders',
            label: 'Orders',
            element: GorseleOneOrders,
            nav: true,
            parent: '/gorseleone',
        }),
        new AppRoute({
            path: '/gorseleone/nopriceorders',
            label: 'Orders zonder prijs',
            element: GorseleOneOrdersNoPrice,
            nav: true,
            parent: '/gorseleone',
        }),
        new AppRoute({
            path: '/gorseleone/users',
            label: 'Gebruikers',
            element: GorseleOneUsers,
            nav: true,
            parent: '/gorseleone',
            pathForDetail: true,
        }),
        new AppRoute({
            path: '/loodswezen',
            label: 'Loodswezen',
            nav: true,
            icon: CargoShipIcon,
        }),
        new AppRoute({
            path: '/loodswezen/planning',
            label: 'Planning',
            element: LoodswezenPlanning,
            nav: true,
            parent: '/loodswezen',
        }),
        new AppRoute({
            path: '/loodswezen/standby',
            label: 'Standby regeling',
            element: LoodswezenStandby,
            nav: true,
            parent: '/loodswezen',
        }),
        new AppRoute({
            path: '/orderchecks',
            label: 'Order controle',
            element: OrderChecks,
            icon: ThumbsUpDownIcon,
            nav: true,
        }),
        new AppRoute({
            path: '/customers',
            label: 'Klanten',
            nav: true,
            icon: UserGroupIcon,
        }),
        new AppRoute({
            path: '/customers/customers',
            label: 'Klanten',
            element: Customers,
            nav: true,
            parent: '/customers',
            pathForDetail: true,
        }),
        new AppRoute({
            path: '/customers/customergroups',
            label: 'Klantengroepen',
            element: Customergroups,
            nav: true,
            parent: '/customers',
            pathForDetail: true,
        }),
        new AppRoute({
            path: '/customers/assigners',
            label: 'Opdrachtgevers',
            element: Assigners,
            nav: true,
            parent: '/customers',
        }),
        new AppRoute({
            path: '/suppliers',
            label: 'Leveranciers',
            element: Suppliers,
            nav: true,
            icon: UserMultiple02Icon,
        }),
        new AppRoute({
            path: '/staff',
            label: 'Personeel',
            nav: true,
            icon: LaborIcon,
        }),
        new AppRoute({
            path: '/staff/employers',
            label: 'Werkgevers',
            element: Employers,
            nav: true,
            parent: '/staff',
        }),
        new AppRoute({
            path: '/staff/partners',
            label: 'Maatschappijen',
            element: Partners,
            nav: true,
            parent: '/staff',
        }),
        new AppRoute({
            path: '/staff/staff',
            label: 'Personeelsleden',
            element: StaffMembers,
            nav: true,
            parent: '/staff',
        }),
        new AppRoute({
            path: '/staff/advancepayments',
            label: 'Voorschotten',
            element: AdvancePayments,
            nav: true,
            parent: '/staff',
        }),
        new AppRoute({
            path: '/ships',
            label: 'Schepen',
            element: Ships,
            nav: true,
            icon: SailboatCoastalIcon,
        }),
        new AppRoute({
            path: '/garage',
            label: 'Garage',
            nav: true,
            icon: GarageIcon,
        }),
        new AppRoute({
            path: '/garage/vehicles',
            label: 'Voertuigen',
            element: Vehicles,
            nav: true,
            parent: '/garage',
        }),
        new AppRoute({
            path: '/garage/vehiclemodels',
            label: 'Voertuigmodellen',
            element: VehicleModels,
            nav: true,
            parent: '/garage',
        }),
        new AppRoute({
            path: '/garage/vehiclecategories',
            label: 'Voertuigcategorieën',
            element: VehicleCategories,
            nav: true,
            parent: '/garage',
        }),
        new AppRoute({
            path: '/garage/materials',
            label: 'Materiaal',
            element: Materials,
            nav: true,
            parent: '/garage',
        }),
        new AppRoute({
            path: '/garage/tomtomtracks',
            label: 'TomTom Tracks',
            element: TomTomTracks,
            nav: true,
            parent: '/garage',
        }),
        new AppRoute({
            path: '/manuals',
            label: 'Handleidingen',
            element: Manuals,
            nav: true,
            icon: Book02Icon,
        }),
        new AppRoute({
            path: '/users',
            label: 'Gebruikers',
            element: Users,
            nav: true,
            icon: UserIcon,
        }),
        new AppRoute({
            path: '/replication',
            label: 'Replicatie',
            nav: true,
            icon: DatabaseSync01Icon,
            role: AppRoles.Admin,
        }),
        new AppRoute({
            path: '/replication/status',
            label: 'Status',
            element: ReplicationStatus,
            nav: true,
            parent: '/replication',
        }),
        new AppRoute({
            path: '/replication/logs',
            label: 'Logs',
            element: ReplicationLogs,
            nav: true,
            parent: '/replication',
        }),
        new AppRoute({
            path: '/replication/jobs',
            label: 'Jobs',
            element: ReplicationJobs,
            nav: true,
            parent: '/replication',
        }),
        new AppRoute({
            path: '/taskmanager',
            label: 'Taak Manager',
            nav: true,
            icon: ArrowDataTransferVerticalIcon,
            role: AppRoles.Admin,
        }),
        new AppRoute({
            path: '/taskmanager/status',
            label: 'Status',
            element: TaskManagerStatus,
            nav: true,
            parent: '/taskmanager',
        }),
        new AppRoute({
            path: '/taskmanager/logs',
            label: 'Logs',
            element: TaskManagerLogs,
            nav: true,
            parent: '/taskmanager',
        }),
        new AppRoute({
            path: '/taskmanager/jobs',
            label: 'Jobs',
            element: TaskManagerJobs,
            nav: true,
            parent: '/taskmanager',
        }),
    ]
}

export default app;