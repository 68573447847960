import { AppRole, Role } from "./types/AppRole";

const AdminRole = new Role("admin");
const ManagementRole = new Role("management");
const AccountantRole = new Role("accountant");

export const AppRoles = {
    Admin: new AppRole([AdminRole]),
    Management: new AppRole([AdminRole, ManagementRole]),
    Accountant: new AppRole([AdminRole, AccountantRole]),
    ManagementAccountant: new AppRole([AdminRole, ManagementRole, AccountantRole]),
}