import { currentApp } from "../config";
import { AppRoute } from "./AppRoute";

export enum APPID {
  unknown = "unknown",
  oneoffice = "oneoffice",
  one = "one",
  connect = "connect",
  lis = "lis",
  exxon = "exxon",
}

const appFronts: { [key: string]: APPID } = {
  onev2: APPID.one,
  one: APPID.one,
  connectv2: APPID.connect,
  connect: APPID.connect,
  oneofficev2: APPID.oneoffice,
  oneoffice: APPID.oneoffice,
  exxon: APPID.exxon
};

export type AppLocale = { [key: string]: { translation: any, label: string } };

export type AppConfigType = {
  id: APPID;
  name: string;
  description: string;
  version: number;
  routes: AppRoute[];
  signInEmail?: boolean;
  locales?: AppLocale;
}

export const getAppConfigForID = (apps: Array<AppConfigType>, id: APPID | string): AppConfigType | undefined => {
  if (!apps?.length) return undefined;
  return apps.find((app) => app.id === id);
}

export const getAppFront = (): APPID => {
  const host: string | undefined = window?.location?.host || undefined;
  if (!host) return APPID.unknown;
  if (host.includes('localhost')) return currentApp;
  const hostArr = host.split('.');
  return appFronts[hostArr[0] as string] || APPID.unknown;
};