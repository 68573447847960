import { AppLocale } from "../types/AppConfig";
import nl from '../locales/nl.json';
import en from '../locales/en.json';

const appTranslations: { [key: string]: any } = {
    nl,
    en
}

export const addAppLocales = (locales?: AppLocale): AppLocale | undefined => {
    if (!locales) return undefined;
    const localeKeys = Object.keys(locales);
    let newLocales: AppLocale = {};
    localeKeys.forEach((locale: string) => {
        if (!appTranslations[locale]) throw new Error(`No app translation for locale ${locale}`);
        const appT = appTranslations[locale] || {};
        newLocales[locale] = {
            translation: { ...locales[locale].translation, ...appT },
            label: locales[locale].label
        }
    })
    return newLocales;
}