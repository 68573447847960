import { lazy } from "react";
import { AppConfigType, APPID } from "../types/AppConfig";
import { AppRoute } from "../types/AppRoute";
import { Task01Icon } from "@hugeicons/react";
import nl from './connect/locales/nl.json';
import en from './connect/locales/en.json';

const ConnectOrders = lazy(() => import("../views/connect/orders/ConnectOrders"));

const app: AppConfigType = {
    id: APPID.connect,
    name: "Gorselé Connect",
    description: "Gorselé Connect is a public order application for Gorselé customers",
    version: 2,
    locales: {
        nl: {
            translation: nl,
            label: 'Nederlands',
        },
        en: {
            translation: en,
            label: 'English'
        },
    },
    routes: [
        new AppRoute({
            path: '/',
            label: 'navigation.orders',
            element: ConnectOrders,
            nav: true,
            icon: Task01Icon,
            index: true,
        }),
    ]
}

export default app;