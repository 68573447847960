import { jwtDecode as decode } from 'jwt-decode';
import { AppUser } from './types/AppUser';
import { getAppFront } from './types/AppConfig';

type JwtPayload = {
  app?: string;
  user: AppUser;
}

export const getUserForToken = (token: string): AppUser | undefined => {
  if (isTokenExpired(token)) {
    return undefined;
  }
  let decoded = token ? decode(token) as JwtPayload : null;
  if (decoded?.user) {
    let u = decoded.user as AppUser;
    return u;
  }
  return undefined;
};

const getTokenExpirationDate = (encodedToken: string): Date | undefined => {
  const token = decode(encodedToken);
  if (!token.exp) {
    return undefined;
  }
  const date = new Date(0);
  date.setUTCSeconds(token.exp);
  return date;
};

const isTokenExpired = (token: string): boolean => {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate ? expirationDate < new Date() : true;
};

const isApp = (token: string): boolean => {
  let decoded = token ? decode(token) as JwtPayload : undefined;
  if (decoded?.app === getAppFront()) {
    return true;
  }
  return false;
};

export const isValidToken = (token: string | undefined): boolean => {
  return !!token && !isTokenExpired(token) && isApp(token);
};
