import { AppConfigType, APPID } from '../types/AppConfig';
import OneOffice from './OneOffice';
import One from './One';
import Exxon from './Exxon';
import Connect from './Connect';

const apps: Array<AppConfigType> = [
    {
        id: APPID.unknown,
        name: 'Gorselé Apps',
        description: 'Gorsele Application container',
        version: 1,
        routes: [],
    },
    OneOffice,
    One,
    Exxon,
    Connect
];

export default apps;
