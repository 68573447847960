import { APPID, getAppFront } from './types/AppConfig';
import { City } from './types/City';

export const buildversion: number = 1.1;
export const currentApp: APPID = APPID.one;
export const replicationAppURI: string =
  'https://gorsele-replication.herokuapp.com/api/';
export const taskschedulerAppURI: string =
  'https://gorsele-scheduler.herokuapp.com/api/';
export const geodataAppURI: string =
  'https://gorsele-geodata.herokuapp.com/api/protected/';
export const appURI: string = process.env.NODE_ENV === 'development' ? 'http://localhost:3088/api/' : 'https://gorsele-back-0f8f65fabf21.herokuapp.com/api/';
export const googleGeocodeURI: string = 'https://maps.googleapis.com/maps/api/geocode/json';
export const googleApiKey: string = 'AIzaSyDxxAPjtL_IoxLephQ4LvWORwe-LFHpF64';
export const gmapKey: string = 'AIzaSyC3srYRgae0wLC3p46FbjdvP8W9cQqLhkM';
export const webfleetURI: string =
  'https://csv.webfleet.com/extern?account=autocarsgorsele&username=webfleet&password=webfleet1!&apikey=4247b6c5-e95c-4184-9120-0de677ba5075&lang=nl&outputformat=json&action=showObjectReportExtern&filterstring=';
export const marineTrafficURI: string = 'https://services.marinetraffic.com/api/';
export const marineTrafficAPIKey: string = '516021a0ed67d097b372242ee70065838921e481';
export const awsAccessKey: string = 'AKIA4ZZF2SCZLATATJMJ';
export const awsSecretKey: string = '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';
export const gorseleCustomerID: string = '571f59698fa9be68034233c3';
export const gorseleCustomerGroupID: string = '571f58c38fa9be68034233c2';
export const kalloCityGeometry: City = {
  _id: 'x',
  city: 'Kallo',
  fullCity: '9120 Kallo',
  zip: 9120,
  geometry: { coordinates: [4.27877, 51.25254], type: 'Point' },
};
export const JWT: string = `gorsele-${getAppFront() || 'app'}-jwtv${buildversion}`;
