import { PulseLoader } from 'react-spinners';

type props = {
  [key: string]: any;
};

const Loader = (props: props) => {
  return <PulseLoader color="black" size="10px" {...props} />;
};

export default Loader;
